// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  text-align: left;
  background: linear-gradient(
    90deg,
    rgba(254, 118, 117, 0.12) 0%,
    rgba(204, 129, 185, 0.12) 34.2%,
    rgba(26, 168, 210, 0.12) 68.81%,
    rgba(76, 210, 217, 0.12) 100%
  );
  margin-top: 0;
  padding: 1% 0;
}
.footer-trademark-content {
  margin: auto;
  font-size: 14px;
  width: fit-content;
  color: #010101;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer-New.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,SAAS;EACT,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB;;;;;;GAMC;EACD,aAAa;EACb,aAAa;AACf;AACA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".footer {\n  /* position: absolute; */\n  bottom: 0;\n  width: 100%;\n  font-family: Manrope;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 19.12px;\n  text-align: left;\n  background: linear-gradient(\n    90deg,\n    rgba(254, 118, 117, 0.12) 0%,\n    rgba(204, 129, 185, 0.12) 34.2%,\n    rgba(26, 168, 210, 0.12) 68.81%,\n    rgba(76, 210, 217, 0.12) 100%\n  );\n  margin-top: 0;\n  padding: 1% 0;\n}\n.footer-trademark-content {\n  margin: auto;\n  font-size: 14px;\n  width: fit-content;\n  color: #010101;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
