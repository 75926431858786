// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notes-manager {
  position: relative;
  border: 1px solid #ccc;
}

.notes-manager-content {
  height: 102%;
  width: 100%;
  overflow-y: auto;
  background: rgba(251, 251, 253, 1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/NotesPage/Notes.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,kCAAkC;AACpC","sourcesContent":[".notes-manager {\n  position: relative;\n  border: 1px solid #ccc;\n}\n\n.notes-manager-content {\n  height: 102%;\n  width: 100%;\n  overflow-y: auto;\n  background: rgba(251, 251, 253, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
