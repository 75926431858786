// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar-Header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  color: black;
  /* margin-bottom: 1%; */
  background: linear-gradient(
    90deg,
    rgba(254, 118, 117, 0.08) 0%,
    rgba(204, 129, 185, 0.08) 34.2%,
    rgba(26, 168, 210, 0.08) 68.81%,
    rgba(76, 210, 217, 0.08) 100%
  );
  box-shadow: 0px 4px 28.8px 0px rgba(0, 0, 0, 0.08);
  min-width: 100%;
}

.Navbar-Header-Items {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0 58px;
}

.Navbar-Header-Link {
  position: relative;
  text-decoration: none;
  color: black;
}

.Navbar-Header-Link.active-link::after {
  content: "";
  position: absolute;
  left: 5%;
  bottom: -5px;
  width: 50%;
  height: 2px;
  background-color: #007bff;
}

.Search-nav-logo {
  display: flex;
  width: 9vw;
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
}
.line-between {
  height: 30px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px;
}
.line-in-between {
  height: 20px;
  width: 1px;
  color: rgba(0, 0, 0, 0.1);
}
.Search-nav-login {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header-New.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,uBAAuB;EACvB;;;;;;GAMC;EACD,kDAAkD;EAClD,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,YAAY;EACZ,UAAU;EACV,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,UAAU;EACV,gDAAgD;AAClD;AACA;EACE,YAAY;EACZ,UAAU;EACV,oCAAoC;EACpC,WAAW;AACb;AACA;EACE,YAAY;EACZ,UAAU;EACV,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".Navbar-Header {\n  height: 70px;\n  display: flex;\n  justify-content: space-between;\n  color: black;\n  /* margin-bottom: 1%; */\n  background: linear-gradient(\n    90deg,\n    rgba(254, 118, 117, 0.08) 0%,\n    rgba(204, 129, 185, 0.08) 34.2%,\n    rgba(26, 168, 210, 0.08) 68.81%,\n    rgba(76, 210, 217, 0.08) 100%\n  );\n  box-shadow: 0px 4px 28.8px 0px rgba(0, 0, 0, 0.08);\n  min-width: 100%;\n}\n\n.Navbar-Header-Items {\n  display: flex;\n  gap: 20px;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 58px;\n}\n\n.Navbar-Header-Link {\n  position: relative;\n  text-decoration: none;\n  color: black;\n}\n\n.Navbar-Header-Link.active-link::after {\n  content: \"\";\n  position: absolute;\n  left: 5%;\n  bottom: -5px;\n  width: 50%;\n  height: 2px;\n  background-color: #007bff;\n}\n\n.Search-nav-logo {\n  display: flex;\n  width: 9vw;\n  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */\n}\n.line-between {\n  height: 30px;\n  width: 1px;\n  background-color: rgba(0, 0, 0, 0.1);\n  border: 1px;\n}\n.line-in-between {\n  height: 20px;\n  width: 1px;\n  color: rgba(0, 0, 0, 0.1);\n}\n.Search-nav-login {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 10px;\n  padding: 20px 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
