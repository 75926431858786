// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Manrope;
} */
html,
body,
.root,
.App {
  height: 100%;
  margin: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: manrope;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;GAKG;AACH;;;;EAIE,YAAY;EACZ,SAAS;EACT,UAAU;EACV,SAAS;EACT,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,YAAY;AACd;AACA;EACE;aACW;AACb","sourcesContent":["/* body {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: Manrope;\n} */\nhtml,\nbody,\n.root,\n.App {\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  margin: 0;\n  text-align: center;\n  font-family: manrope;\n  color: black;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n#root{\n  height: 100%;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
