// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(58, 58, 64, 0.6);

  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.confirm-header {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}
.confirm-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.confirm-message {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

.save-confirm-buttons {
  margin-top: 20px;
  /* width: 30px; */
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.confirm-save-button,
.confirm-discard-button,
.confirm-cancel-button {
  flex: 1 1;
  padding: 10px;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
}

.confirm-save-button {
  color: rgba(78, 78, 86, 1);
  background: rgba(234, 234, 236, 1);
}

.confirm-discard-button {
  background: #e57373;
  color: white;
}

.confirm-cancel-button {
  background-color: #bbb;
  color: white;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/utils/ConfirmOverlay.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iCAAiC;;EAEjC,0CAA0C;EAC1C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,iDAAiD;AACnD;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,SAAS;AACX;;AAEA;;;EAGE,SAAO;EACP,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,2CAA2C;AAC7C","sourcesContent":[".confirm-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(58, 58, 64, 0.6);\n\n  /* background-color: rgba(0, 0, 0, 0.5); */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n.confirm-header {\n  display: flex;\n  justify-content: space-between;\n  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */\n}\n.confirm-dialog {\n  background-color: white;\n  padding: 20px;\n  border-radius: 20px;\n  width: 300px;\n  text-align: center;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.confirm-message {\n  font-size: 16px;\n  margin-bottom: 20px;\n  color: #333;\n}\n\n.save-confirm-buttons {\n  margin-top: 20px;\n  /* width: 30px; */\n  display: flex;\n  justify-content: flex-end;\n  gap: 30px;\n}\n\n.confirm-save-button,\n.confirm-discard-button,\n.confirm-cancel-button {\n  flex: 1;\n  padding: 10px;\n  border: none;\n  border-radius: 30px;\n  font-size: 14px;\n  cursor: pointer;\n}\n\n.confirm-save-button {\n  color: rgba(78, 78, 86, 1);\n  background: rgba(234, 234, 236, 1);\n}\n\n.confirm-discard-button {\n  background: #e57373;\n  color: white;\n}\n\n.confirm-cancel-button {\n  background-color: #bbb;\n  color: white;\n  border-bottom: 2px solid rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
