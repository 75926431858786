// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); /* Slight opacity */
  z-index: 9999; /* On top of everything */
}

.loading-spinner {
  font-size: 24px;
  color: #000;
}
.custom-loader {
  width: 50px;
  height: 50px;
  display: grid;
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  --c: radial-gradient(farthest-side, #1692f8 92%, rgba(179, 53, 53, 0));
  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: s2 1s infinite;
}

.custom-loader::before {
  margin: 4px;
  filter: hue-rotate(45deg);
  background-size: 8px 8px;
  animation-timing-function: linear;
}

@keyframes s2 {
  100% {
    transform: rotate(0.5turn);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,0CAA0C,EAAE,mBAAmB;EAC/D,aAAa,EAAE,yBAAyB;AAC1C;;AAEA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAEA;;EAEE,WAAW;EACX,cAAc;EACd,sEAAsE;EACtE;kBACgB;EAChB,0BAA0B;EAC1B,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,wBAAwB;EACxB,iCAAiC;AACnC;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["/* Loading.css */\n.loading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(255, 255, 255, 0.4); /* Slight opacity */\n  z-index: 9999; /* On top of everything */\n}\n\n.loading-spinner {\n  font-size: 24px;\n  color: #000;\n}\n.custom-loader {\n  width: 50px;\n  height: 50px;\n  display: grid;\n}\n\n.custom-loader::before,\n.custom-loader::after {\n  content: \"\";\n  grid-area: 1/1;\n  --c: radial-gradient(farthest-side, #1692f8 92%, rgba(179, 53, 53, 0));\n  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,\n    var(--c) 0 50%;\n  background-size: 12px 12px;\n  background-repeat: no-repeat;\n  animation: s2 1s infinite;\n}\n\n.custom-loader::before {\n  margin: 4px;\n  filter: hue-rotate(45deg);\n  background-size: 8px 8px;\n  animation-timing-function: linear;\n}\n\n@keyframes s2 {\n  100% {\n    transform: rotate(0.5turn);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
